import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '@/assets/images/obralink-logo.png';
import background_image from '@/assets/images/obralink-background.png';
import useFetch from '@/hooks/useFetch';
import { AuthParams } from '@/@types/auth';
import { ValidEnvironments } from '@/contexts/AuthContext';
import EP_ENVIRONMENTS from '@/utils/environments';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

interface LoginProps {
  onLogin: (authParams: AuthParams) => void;
  onLogout: () => void;
}

const Login: React.FC<LoginProps> = ({ onLogin, onLogout }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const { data, loading, error, execute, status } = useFetch<any>({
    url: '/login/',
    method: 'POST',
    body: JSON.stringify({ username, password }),
    immediate: false,
  });

  const [environment, setEnvironment] = useState<ValidEnvironments>('dev');
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const env = searchParams.get('environment') as ValidEnvironments;
    if (env && process.env.ENVIRONMENT !== 'prod' && env !== 'prod') {
      setEnvironment(env);
    } else if (process.env.ENVIRONMENT === 'prod') {
      setEnvironment('prod');
    }

    searchParams.delete('environment');
  }, []);

  useEffect(() => {
    if (data && status === 200) {
      window.history.replaceState({}, '', `${window.location.pathname}`);
      // Asegúrate de que data tenga los parámetros esperados
      const { csrf_token, session_id, jwt } = data;

      onLogin({
        access_token: jwt.access,
        refresh_token: jwt.refresh,
        csrf_token: csrf_token,
        environment: environment,
      });
    }
  }, [data, status, onLogin]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    execute();
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <div
      className='h-screen items-center flex flex-col justify-center w-full relative bg-cover bg-center'
      style={{ backgroundImage: `url(${background_image})` }}
    >
      <div className='absolute inset-0 bg-primary-dark opacity-10'></div>
      <div className='relative z-10 m-10 bg-primary-light bg-opacity-60 p-8 rounded-lg shadow-lg flex flex-col items-center w-full max-w-md backdrop-blur-sm '>
        <img src={logo} alt='Obralink Logo' className='mb-8 w-32' />
        <form onSubmit={handleSubmit} className='w-full'>
          <div className='form-group mb-4'>
            <label className='block text-gray-700 font-bold mb-2'>
              {t('login.username')}
            </label>
            <input
              type='text'
              className='form-control w-full p-2 border border-gray-300 rounded-md'
              value={username}
              onChange={handleUsernameChange}
              required
            />
          </div>
          <div className='form-group mb-6'>
            <label className='block text-gray-700 font-bold mb-2'>
              {t('login.password')}
            </label>
            <div className='relative'>
              <input
                type={showPassword ? 'text' : 'password'}
                className='form-control w-full p-2 border border-gray-300 rounded-md'
                value={password}
                onChange={handlePasswordChange}
                required
              />
              <button
                type='button'
                className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5'
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
              </button>
            </div>
            <a
              className='flex text-xs hover:underline text-primary-dark py-2 w-full justify-end'
              href={`${
                EP_ENVIRONMENTS[environment as string]
              }/system/sdx/users/recover/send/`}
            >
              {t('navbar.change_password')}
            </a>
          </div>
          <div className='flex justify-center'>
            <button
              type='submit'
              className={`flex justify-center m-2 py-2 px-10 text-primary-dark  bg-gray-100 hover:bg-white rounded-xl hover:bg-right-bottom cursor-pointer ${
                loading
                  ? 'transition-all duration-500 bg-gradient-to-r to-white via-primary-yellow from-gray-100 bg-size-300 ease-in-out'
                  : 'from-gray-300 to-gray-400'
              }`}
            >
              {t('login.submit')}
            </button>
          </div>
        </form>

        {error && (
          <p className='text-primary-dark mt-4'>
            {t('login.error', { error })}
          </p>
        )}
      </div>
    </div>
  );
};

export default Login;
